import { useState } from "react"
import MenuIcon from "@mui/icons-material/Menu"
import { Divider, Drawer, Button, Link, Logo, Avatar, Box, Typography } from "@repo/ui"
import CloseIcon from "@mui/icons-material/Close"
import SourceIcon from "@mui/icons-material/Source"
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn"
import AutoStoriesIcon from "@mui/icons-material/AutoStories"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import { useRouter } from "next/navigation"
import { goToMenuItem } from "@repo/utils/menuUtils"
import Image from "next/image"
import { userMenuItems, resourceMenuItems } from "@/constants/topMenuItems"
import UpgradeNowButton from "@/components/molecules/UpgradeNowButton"
import appCategories from "@/constants/categories"
import RedirectBackLinkWrapper from "@/components/molecules/RedirectBackLinkWrapper"
import TopMenuContentItem from "./TopMenuContentItem"
import styles from "./TopMenuMobile.module.scss"

const TopMenuMobile = ({ userLoggedIn, user, setUserState }) => {
  const [open, setOpen] = useState(false)
  const [screenMode, setScreenMode] = useState("start")
  const { subjects, languages } = appCategories

  const { push } = useRouter()

  const onDrawerClose = () => {
    setOpen(false)
  }
  const onDrawerOpen = () => {
    setOpen(true)
  }

  const contentItems = [
    {
      label: "Catalog",
      onClick: () => {
        setScreenMode("catalog")
      },
      icon: <AutoStoriesIcon className={styles["menu-item-icon"]} />,
    },
    {
      label: "Resources",
      onClick: () => {
        setScreenMode("resources")
      },
      icon: <SourceIcon className={styles["menu-item-icon"]} />,
    },
    {
      label: "Pricing",
      route: "/pricing",
      icon: <MonetizationOnIcon className={styles["menu-item-icon"]} />,
    },
  ]

  const contentItemsMapper = ({ label, icon, onClick, route, target }) => {
    if (route) {
      return (
        <Link
          key={label}
          to={route}
          className={styles["top-menu-content-item-link"]}
          target={target}
        >
          <TopMenuContentItem label={label} icon={icon} onClick={onClick} hasRoute={Boolean(route)} />
        </Link>
      )
    }

    return <TopMenuContentItem key={label} label={label} icon={icon} onClick={onClick} />
  }

  const resourceMenuItemMapper = ({ name, route, target }) =>
    contentItemsMapper({ label: name, icon: null, route, target })

  const subjectMapper = ({ subject, urlSlug }) =>
    contentItemsMapper({ label: subject, icon: null, route: `/${urlSlug}` })

  const languagesMapper = ({ language, urlSlug }) =>
    contentItemsMapper({ label: language, icon: null, route: `/${urlSlug}` })

  const onResetModeClick = () => {
    setScreenMode("start")
  }

  const userMenuItemsMapper = ({ route, onClick, icon, label, divider }) => {
    return (
      <div key={label}>
        <Button
          padding="0.5rem 0"
          variant="text"
          onClick={() => goToMenuItem(onClick, push, route, user, setUserState, setScreenMode)}
        >
          <Box style={{ minWidth: 32 }}>{icon}</Box>
          {label}
        </Button>

        {divider && <Divider />}
      </div>
    )
  }

  return (
    <div>
      <div className={styles["hamburger-icon"]}>
        <UpgradeNowButton
          paymentStatus={user.paymentStatus}
          userLoggedIn={Boolean(userLoggedIn)}
          register
        />

        <Button
          edge="start"
          aria-label="open drawer"
          style={{
            color: "#fff",
          }}
          onClick={onDrawerOpen}
          variant="text"
        >
          <MenuIcon fontSize="large" />
        </Button>
      </div>

      <Drawer
        anchor="right"
        open={open}
        onClose={onDrawerClose}
        onOpen={onDrawerOpen}
        header={
          <>
            <Box style={{ display: "flex", alignItems: "center" }}>
              {screenMode !== "start" ? (
                <ChevronLeftIcon
                  onClick={onResetModeClick}
                  style={{ color: "#fff", width: 32 }}
                  className={styles["menu-item-icon"]}
                />
              ) : (
                <div style={{ width: 32 }}></div>
              )}
              <Link to="/" style={{ flex: 2, display: "flex", justifyContent: "center" }}>
                <Logo Image={Image} />
              </Link>

              <Button onClick={onDrawerClose} variant="text" aria-label="Close">
                <CloseIcon style={{ color: "#fff" }} />
              </Button>
            </Box>
            <Divider style={{ marginTop: "0.5rem", backgroundColor: "#fff" }} />
          </>
        }
      >
        <Box
          backgroundColor="var(--background-color)"
          padding="1rem"
          margin="0 0 2rem 0"
        >
          {screenMode === "start" && (
            <Box style={{ marginTop: "2rem", flex: 1 }}>
              <Typography variant="h4" className={styles["menu-items-title"]} margin="0 0 1rem 0">
                Content
              </Typography>

              {contentItems.map(contentItemsMapper)}
            </Box>
          )}

          {screenMode === "catalog" && (
            <Box style={{ marginTop: "2rem" }}>
              <Typography variant="h4" className={styles["menu-items-title"]} margin="0 0 1rem 0">
                <AutoStoriesIcon className={styles["menu-item-icon"]} /> Catalog
              </Typography>

              {contentItemsMapper({
                label: "Full Catalog",
                icon: null,
                route: `/catalog`,
              })}

              {subjects.map(subjectMapper)}

              {languages.map(languagesMapper)}
            </Box>
          )}

          {screenMode === "resources" && (
            <Box style={{ marginTop: "2rem" }}>
              <Typography variant="h4" className={styles["menu-items-title"]} margin="0 0 1rem 0">
                <SourceIcon className={styles["menu-item-icon"]} /> Resources
              </Typography>

              {resourceMenuItems.map(resourceMenuItemMapper)}
            </Box>
          )}

          {userLoggedIn ? (
            <div>
              {screenMode === "start" &&
                contentItemsMapper({
                  label: user?.email,
                  icon: <Avatar src={user.profilePicture} alt={user.fullName} />,
                  onClick: () => {
                    setScreenMode("profile")
                  },
                })}

              {screenMode === "profile" && (
                <Box style={{ marginTop: "2rem" }}>
                  <Typography variant="h4" className={styles["menu-items-title"]} margin="0 0 1rem 0">
                    {user.fullName}
                  </Typography>

                  {userMenuItems.map(userMenuItemsMapper)}
                </Box>
              )}
            </div>
          ) : (
            <div className={styles["login-buttons"]}>
              <RedirectBackLinkWrapper url="/login" >
                <Button>Login</Button>
              </RedirectBackLinkWrapper>

              <RedirectBackLinkWrapper url="/register" >
                <Button color="secondary">Register</Button>
              </RedirectBackLinkWrapper>
            </div>
          )}
        </Box>
      </Drawer>
    </div>
  )
}

export default TopMenuMobile
