"use client"

import { useState } from "react"
import { Divider, Button, Tooltip, Avatar, DropdownMenu, Box } from "@repo/ui"
import { useRouter } from "next/navigation"
import { goToMenuItem } from "@repo/utils/menuUtils"
import { userMenuItems } from "@/constants/topMenuItems"

const UserTopMenu = ({ user, setUserState }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const { push } = useRouter()

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const userMenuItemsMapper = ({ label, route, icon, onClick, divider }) => (
    <div key={label}>
      <Button
        variant="text"
        onClick={() => goToMenuItem(onClick, push, route, user, setUserState)}
      >
        <Box style={{ minWidth: 32 }}>{icon}</Box>
        {label}
      </Button>
      {divider && <Divider style={{ borderColor: "var(--border-color)" }} />}
    </div>
  )

  return (
    <>
      <Tooltip title="User Menu">
        <Button
          padding="0"
          variant="text"
          onClick={handleClick}
          size="small"
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Avatar src={user.profilePicture} alt={user.fullName} />
        </Button>
      </Tooltip>

      <DropdownMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {userMenuItems.map(userMenuItemsMapper)}
      </DropdownMenu>
    </>
  )
}

export default UserTopMenu
