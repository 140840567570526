const subjects = [
    {
        id: 17,
        imageUrl:
            'https://static.code-hl.com/resources/categories/Artificial+Intelligence+(AI).png',
        urlSlug: 'ai',
        subject: 'Artificial Intelligence (AI)',
        description:
            'Unravel the mysteries of AI and get acquainted with the fascinating world of intelligent systems. Understand the algorithms and techniques that give machines the ability to learn, reason and interact.\n\n',
    },
    // {
    //     id: 23,
    //     imageUrl: "https://static.code-hl.com/resources/categories/Data+Science.png",
    //     urlSlug: "data-science",
    //     subject: "Data Science",
    //     description:
    //         "Delve into Data Science, the field that uses scientific methods to extract knowledge and insights from data. Understand the interplay of statistics, programming, and domain expertise in making data-driven decisions.\n\n",
    // },
    // {
    //     id: 26,
    //     imageUrl: "https://static.code-hl.com/resources/categories/DevOps.png",
    //     urlSlug: "devops",
    //     subject: "DevOps",
    //     description:
    //         "Explore DevOps, the practice that bridges development and operations for better, faster software production. Understand its principles, practices, and tools that promote collaboration and automation in software development.\n\n",
    // },
    // {
    //     id: 32,
    //     imageUrl: "https://static.code-hl.com/resources/categories/Web+Design.png",
    //     urlSlug: "web-design",
    //     subject: "Web Design",
    //     description:
    //         "Discover Web Design, the art of creating aesthetically pleasing and user-friendly websites. Learn about color theory, typography, layout, and user experience to create visually appealing and intuitive web interfaces.\n\n",
    // },
    {
        id: 33,
        imageUrl: 'https://static.code-hl.com/resources/categories/Web+Development.png',
        urlSlug: 'web-development',
        subject: 'Web Development',
        description:
            'Navigate the vast landscape of Web Development. Understand both client-side and server-side development to build interactive, robust, and efficient websites and web applications.\n\n\n\n\n',
    },
    {
        id: 36,
        imageUrl: 'https://static.code-hl.com/resources/categories/Programming+Best+Practices.png',
        urlSlug: 'programming-best-practices',
        subject: 'Programming Best Practices',
        description:
            'All about improving programming skills. Topics include coding style, code readability, debugging, design patterns, testing, and more. The aim is to provide tips and advice to programmers of all levels to write better code and follow industry standards.',
    },
    {
        id: 37,
        imageUrl: 'https://static.code-hl.com/resources/categories/Learning+Tips.png',
        urlSlug: 'learning-tips',
        subject: 'Learning Tips',
        description:
            'Dive into Learning Tips, your guide to mastering new concepts effectively. From memorization tricks to time-management strategies, discover the best ways to enhance your learning journey and absorb knowledge efficiently.',
    },
    {
        id: 39,
        imageUrl: 'https://static.code-hl.com/resources/categories/Career+Advice.png',
        urlSlug: 'career',
        subject: 'Career Advice',
        description:
            "Embark on a clearer path with Career Advice. Whether you're starting out, switching industries, or climbing the corporate ladder, gain insights into career planning, growth strategies, and navigating workplace challenges.",
    },
    {
        id: 40,
        imageUrl: 'https://static.code-hl.com/resources/categories/Get+Inspired.png',
        urlSlug: 'get-inspired',
        subject: 'Get Inspired',
        description:
            'Plunge into Get Inspired, a treasure trove of motivational stories and transformative ideas. Learn from thought leaders, pioneers, and everyday heroes who challenge the status quo and ignite the spark of possibility.',
    },
    {
        id: 41,
        imageUrl: 'https://static.code-hl.com/resources/categories/Learner+Stories.png',
        urlSlug: 'learner-stories',
        subject: 'Learner Stories',
        description:
            'Step into the world of Learner Stories, tales of determination, growth, and achievement. Discover how individuals worldwide have transformed their lives through education and the pursuit of knowledge.',
    },
];

const languages = [
    {
        id: 1,
        // compilerDescription: "HTML & CSS",
        imageUrl: 'https://static.code-hl.com/resources/categories/html-css-languages.png',
        urlSlug: 'html-and-css',
        language: 'HTML & CSS',
        description:
            "Explore the building blocks of web development in our HTML & CSS category. Here, you'll master HTML for structuring webpages and CSS for styling them. Delve into these foundational languages and begin your journey towards creating captivating websites.",
    },
    {
        id: 2,
        compilerDescription:
            'Use our web-based compiler (interpreter) to write and run Python code. Python Shell, such as IDLE, allows for user inputs in our Python compiler.',
        imageUrl: 'https://static.code-hl.com/resources/categories/python-language.png',
        urlSlug: 'python',
        language: 'Python',
        description:
            'Embark on your coding voyage with Python, a versatile and beginner-friendly language. Dive into data science, web development, automation, and more with this widely adopted and powerful language.\n\n',
    },
    {
        id: 3,
        compilerDescription:
            'The JavaScript online compiler is designed to be easily accessible and convenient for users. It enables you to write JavaScript code and execute it directly on the internet. The JavaScript text editor also facilitates user input and integration with standard libraries. The code is compiled using the node.js compiler.',
        imageUrl: 'https://static.code-hl.com/resources/categories/javascript-language.png',
        urlSlug: 'javascript',
        language: 'JavaScript',
        description:
            'Immerse yourself in JavaScript, the dynamic language that breathes life into websites. Learn to create interactive web applications and explore the power of client-side scripting.\n\n',
    },
    {
        id: 4,
        compilerDescription:
            'The Java online compiler is designed to be user-friendly, enabling you to write Java code and execute it directly on the web. The Java text editor additionally facilitates user input and integration with common libraries. The code is compiled using the OpenJDK 11 compiler.',
        imageUrl: 'https://static.code-hl.com/resources/categories/java-language.png',
        urlSlug: 'java',
        language: 'Java',
        description:
            "Welcome to Java, the object-oriented language powering enterprise-grade applications worldwide. Understand Java's robustness and portability, opening doors to vast development opportunities.\n\n",
    },
    // {
    //     id: 7,
    //     imageUrl: "https://static.code-hl.com/resources/categories/ruby-language.png",
    //     urlSlug: "ruby",
    //     language: "Ruby",
    //     description: "Engage with Ruby, an elegant, easy-to-read language beloved for web development. Explore Ruby's simplicity and expressiveness that makes programming a delightful experience.\n\n"
    // },
    {
        id: 8,
        compilerDescription:
            'The C++ online compiler is designed to be easily accessible and navigable, enabling users to write C++ code and execute it directly on the web. The C++ text editor additionally facilitates user input and integration with standard libraries. It utilizes the GCC (g++) compiler to compile code.',
        imageUrl: 'https://static.code-hl.com/resources/categories/cpp-language.png',
        urlSlug: 'cpp',
        language: 'C++',
        description:
            'Enter the realm of C++, a language known for its performance and control over system resources. Learn how C++ enables development of game engines, real-time systems, and high-performance software.\n\n',
    },
    {
        id: 12,
        compilerDescription:
            'The Go online compiler is designed to be user-friendly, enabling you to write Golang code and execute it directly on the web. The Golang text editor additionally facilitates user input and integration with common libraries. The code is compiled using the Go compiler.',
        imageUrl: 'https://static.code-hl.com/resources/categories/golang-language.png',
        urlSlug: 'golang',
        language: 'Golang',
        description:
            'Begin your journey with Go, a statically typed, compiled language that excels in simplicity and efficiency. Learn how Go (Golang) empowers software development with its speed and concurrency.\n\n',
    },
];

const appCategories = {
    subjects,
    languages,
};

export default appCategories;
