"use client"

import { Link , Box } from "@repo/ui"
import * as React from "react"
import { useUserStore } from "@/store/mutable-data/user/UserStoreContext"

const TopMenuPaymentNeeded = () => {
  const user = useUserStore(state => state.user)
  const paymentNeeded = user.paymentStatus === "PAYMENT_NEEDED"

  if (!paymentNeeded) {
    return null
  }
  return (
    <Box
      padding={0.5}
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{ background: "var(--red)" }}
    >
      There was an issue with your subscription payment!{" "}
      <Link
        to="/checkout"
        style={{ fontWeight: "bold", marginLeft: 10, color: "var(--primary-color)" }}
      >
        Click here to learn more
      </Link>
    </Box>
  )
}

export default TopMenuPaymentNeeded
