'use client';

import { Link } from '@repo/ui';
import type { ReactNode } from 'react';
import { usePathname } from 'next/navigation';

const RedirectBackLinkWrapper = ({
    url,
    children,
    style,
    nextUrl,
    ...rest
}: {
    url: string;
    children: ReactNode;
    style?: any;
    nextUrl?: string;
    pathname?: string;
}) => {
    const pathname = usePathname();

    const redirectUrl = `${url}?next=${nextUrl || pathname}`;
    return (
        <Link to={redirectUrl} style={style} {...rest}>
            {children}
        </Link>
    );
};

export default RedirectBackLinkWrapper;
