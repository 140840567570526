export const menuCallback = (user: any, setUserState: any, setScreenMode: any) => {
    if (setUserState) {
        setUserState(user);
    }

    if (setScreenMode) {
        setScreenMode('start');
    }
};

export const goToMenuItem = (
    onClick: any,
    push: any,
    route: any,
    user: any,
    setUserState: any,
    setScreenMode?: any,
) => {
    if (onClick) {
        onClick(user.id, (userInherit: any) =>
            menuCallback(userInherit, setUserState, setScreenMode),
        );
    } else if (route) {
        const link = route(user.username);

        push(link);
    }
};
