'use client';

import React from 'react';
import { Link, Button } from '@repo/ui';
import { useUserStore } from '@/store/mutable-data/user/UserStoreContext';
import RedirectBackLinkWrapper from '../RedirectBackLinkWrapper';

interface IUpgradeNowButton {
    register?: boolean;
}

const UpgradeNowButton: React.FC<IUpgradeNowButton> = ({ register }) => {
    const { user } = useUserStore();
    const userLoggedIn = Boolean(user.id);

    const paymentStatusNotActive = user.paymentStatus !== 'ACTIVE';
    if (!paymentStatusNotActive) {
        return null;
    }

    if (!userLoggedIn) {
        if (register) {
            return (
                <RedirectBackLinkWrapper url="/register">
                    <Button>Register</Button>
                </RedirectBackLinkWrapper>
            );
        }

        return (
            <Link to={`/pricing`}>
                <Button>Pricing</Button>
            </Link>
        );
    }

    return (
        <>
            {paymentStatusNotActive && (
                <Link to="/checkout">
                    <Button>Upgrade now</Button>
                </Link>
            )}
        </>
    );
};

export default UpgradeNowButton;
