import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import styles from "./TopMenuContentItem.module.scss"

const TopMenuContentItem = ({ icon, label, onClick, hasRoute }) => {
  return (
    <div
      className={styles["mobile-menu-item"]}
      role="button"
      onClick={onClick}
      aria-label="Top menu content item"
    >
      <div className={styles["mobile-menu-item-left"]}>
        {icon} {label}
      </div>

      {!hasRoute && <ChevronRightIcon />}
    </div>
  )
}

export default TopMenuContentItem
