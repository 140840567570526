"use client"

import { Link } from "@repo/ui"
import * as React from "react"
import { useUserStore } from "@/store/mutable-data/user/UserStoreContext"
import styles from "./TopMenuPricingButton.module.scss"

const TopMenuPricingButton = () => {
  const user = useUserStore(state => state.user)
  const loggedIn = Boolean(user.id)

  if (loggedIn && user.paymentStatus === "ACTIVE") {
    return null
  }

  return (
    <Link to="/pricing" className={styles["pricing-link"]}>
      Pricing
    </Link>
  )
}

export default TopMenuPricingButton
