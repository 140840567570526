'use client';

import * as React from 'react';
import { TopMenu } from '@repo/ui';
import Image from 'next/image';
import TopMenuRightSide from '@/components/organisms/TopMenu/TopMenuRightSide';
import TopMenuPricingButton from '@/components/organisms/TopMenu/TopMenuPricingButton';
import TopMenuPaymentNeeded from '@/components/organisms/TopMenu/TopMenuPaymentNeeded';
import appCategories from '@/constants/categories';
import { useUserStore } from '@/store/mutable-data/user/UserStoreContext';

const TopMenuWrapper = () => {
    const { user } = useUserStore();
    const paymentNeeded = user.paymentStatus === 'PAYMENT_NEEDED';

    return (
        <TopMenu
            Image={Image}
            hasBanner={paymentNeeded}
            appCategories={appCategories}
            TopMenuRightSide={TopMenuRightSide}
            TopMenuBanner={TopMenuPaymentNeeded}
            TopMenuLeftSideAddition={TopMenuPricingButton}
        />
    );
};

export default TopMenuWrapper;
